module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"alvyApp","short_name":"alvyApp","start_url":"/","background_color":"#FFFFFF","display":"minimal-ui","icon":"src/app-icon/icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-tracking/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-72779821-5","autoStart":false,"anonymize":true,"controlCookieName":"gatsby-gdpr-google-analytics"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
