// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-policy-js": () => import("./../src/pages/cookies-policy.js" /* webpackChunkName: "component---src-pages-cookies-policy-js" */),
  "component---src-pages-dev-index-js": () => import("./../src/pages/dev/index.js" /* webpackChunkName: "component---src-pages-dev-index-js" */),
  "component---src-pages-features-js": () => import("./../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-stuff-cookies-policy-js": () => import("./../src/pages/legal-stuff/cookies-policy.js" /* webpackChunkName: "component---src-pages-legal-stuff-cookies-policy-js" */),
  "component---src-pages-legal-stuff-privacy-policy-js": () => import("./../src/pages/legal-stuff/privacy-policy.js" /* webpackChunkName: "component---src-pages-legal-stuff-privacy-policy-js" */),
  "component---src-pages-legal-stuff-terms-and-conditions-js": () => import("./../src/pages/legal-stuff/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-legal-stuff-terms-and-conditions-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-share-index-js": () => import("./../src/pages/share/index.js" /* webpackChunkName: "component---src-pages-share-index-js" */),
  "component---src-pages-spaced-repetition-js": () => import("./../src/pages/spaced-repetition.js" /* webpackChunkName: "component---src-pages-spaced-repetition-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

